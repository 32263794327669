import React from 'react';
import styles from './NewAbout.module.css';
import { FaLinkedin } from 'react-icons/fa';

const coFounders = [
    {
        name: 'Andy Barkett',
        image: 'andy.jpeg',
        linkedin: 'https://www.linkedin.com/in/andrew-barkett-9147473/'
    },
    {
        name: 'David Garcia',
        image: 'david.jpeg',
        linkedin: 'https://www.linkedin.com/in/david-h-garcia/'
    },
    {
        name: 'Josh Paulson',
        image: 'josh.jpg',
        linkedin: 'https://www.linkedin.com/in/joshuapaulson/'
    }
];

const NewAbout = () => {
    return (
        <div>
            {/* Hero section */}
            <div className={styles['home-container']}>
                <div className={styles['build-different-container']}>
                    <h1 className={styles['build-different-title']}>About Miriel</h1>
                    <p className={styles['home-description2']}>
                        The RAG Platform bringing you the future of app development
                    </p>
                    <p className={styles['team-description']}>
                        With decades of experience, Miriel's 3 founders have built, scaled, and delivered products impacting millions of users and developers. This includes leading search innovation at Google, overseeing the growth of Facebook Messenger and Oculus, and empowering developers with tools like RStudio IDE and Server at Posit. Over the years, we've pioneered the use of machine learning, artificial intelligence, and large language models to build novel applications. Recognizing many teams struggle to embed AI in their products, we created Miriel to equip every developer with the tools to effortlessly integrate AI into their applications, enabling anyone to build transformative new products never thought possible.
                    </p>

                    <div className={styles.grid}>
                        {coFounders.map((founder, index) => (
                            <div key={index} className={styles.card}>
                                <img src={founder.image} alt={founder.name} className={styles.image} />
                                <div className={styles.info}>
                                    <div className={styles.nameContainer}>
                                        <h3 className={styles.name}>{founder.name}</h3>
                                        <a href={founder.linkedin} className={styles.linkedin} target="_blank" rel="noopener noreferrer">
                                            <FaLinkedin />
                                        </a>
                                    </div>
                                    <span className={styles.coFounder}>Founder</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewAbout;
