import React, { useState, useEffect } from 'react';
import styles from './MirielFooter.module.css';
import { ReactComponent as MirielLogo } from './MirielLogoBlackNoText.svg';

const MirielFooter = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <footer className={styles.footer}>
            <div className={styles.footerContainer}>
                <div className={styles.brandSection}>
                    <div className={styles.logoAndName}>
                        <MirielLogo className={styles.logo} alt="Miriel Logo" />
                        <span className={styles.brandName}>MIRIEL</span>
                        <p className={styles.copyright}>
                            © 2025 Miriel, Inc. {isMobile && <br />} All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default MirielFooter;
